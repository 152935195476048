import printJS from "print-js";
import * as changedpi from 'changedpi'
import * as echarts from 'echarts';
import html2canvas from "html2canvas";
export default {
  data() {
    return {
      list: [] ,
      multiple:4,
      fontSize:48,
      images: {
        width: 900,
        height: 640,
      } ,
      imgs: [] ,
      message:null,
    }
  } ,
  mounted() {
    this.images = {
      width: 900 * this.multiple,
      height: 640 * this.multiple,
    }
  },
  methods: {
    // 年级转换
    conversionMap(garde){
      switch ( garde ){
        case 1:
          return '一'
        case 2:
          return '二'
        case 3:
          return '三'
        case 4:
          return '四'
        case 5:
          return '五'
        case 6:
          return '六'
      }
    },

    // 奖励等级转换
    conversionReward(level){
      let num = Number(level)
      switch ( num ){
        case 1:
          return '市级';
        case 2:
          return '区级';
        case 3:
          return '校级';
        case 4:
          return '省级';
        case 5:
          return '国家级';
      }
    },


    // 调用不同的报告图片
    // grade 年级   typo 1上 2下
    getBackgroundImage(grade,type){
        let front = null, back = null;
        switch ( grade ){
          case 1:  // 一年级
            if(type === 1){
              front = require( '@/assets/report/一上、一下、二上正面.png' );
              back = require( '@/assets/report/一二年级第1学期反面.png' );
            }else{
              front = require( '@/assets/report/一上、一下、二上正面.png' );
              back = require( '@/assets/report/一二年级第2学期反面.png' );
            }
            break;
          case 2:  // 二年级
            if(type === 1){
              front = require( '@/assets/report/一上、一下、二上正面.png' );
              back = require( '@/assets/report/一二年级第1学期反面.png' );
            }else{
              front = require( '@/assets/report/二下正面.png' );
              back = require( '@/assets/report/一二年级第2学期反面.png' );
            }
            break;
          case 3:
            if(type === 1){
              front = require( '@/assets/report/三上正面.png' );
              back = require( '@/assets/report/三四五六年级第1学期反面.png' );
            }else{
              front = require( '@/assets/report/三下四五六年级正.png' );
              back = require( '@/assets/report/三四五六年级第2学期反面.png' );
            }
            break;
          case 4:
          case 5:
          case 6:
            if(type === 1){
              front = require( '@/assets/report/三下四五六年级正.png' );
              back = require( '@/assets/report/三四五六年级第1学期反面.png' );
            }else{
              front = require( '@/assets/report/三下四五六年级正.png' );
              back = require( '@/assets/report/三四五六年级第2学期反面.png' );
            }
            break;
        }
        return {
          front,
          back
        }
    },

    // 打印数据
    handlePrintData( item ){
      console.log('item内容：',item)
      return {
        // 第一页
        className: item?.class?.class_name,
        real_name: item?.real_name,
        grade: this.conversionMap(item?.class?.grade),
        semester:  this.conversionMap(item?.semester?.type),

        // 第二页
        JGQH: item?.pingDe?.star?.[0]?.star,      // 家国情怀
        ZRDD: item?.pingDe?.star?.[1]?.star,      // 责任担当
        ZGSJ: item?.pingDe?.star?.[2]?.star,      // 遵规守纪
        WMLM: item?.pingDe?.star?.[3]?.star,      // 文明礼貌
        CXYA: item?.pingDe?.star?.[4]?.star,      // 诚信友爱

        DDFZ: {  // 道德法治
          XXTD: item?.pingDe?.level?.[0]?.children?.[0]?.level,    // 学习态度
          RZSP: item?.pingDe?.level?.[0]?.children?.[1]?.level,    // 认知水平
          SJNL: item?.pingDe?.level?.[0]?.children?.[2]?.level,    // 实践能力
        },
        DFKC: {  // 地方课程
          GCBX: item?.pingDe?.level?.[1]?.children?.[0]?.level,    // 过程表现
          CGSP: item?.pingDe?.level?.[1]?.children?.[1]?.level,    // 成果水平
        },
        Chinese:{     //语文
          A: [    // 识字与写字
            item?.xueYe?.level?.[0]?.children?.[0]?.children[0]?.level,  // 过程表现
            item?.xueYe?.level?.[0]?.children?.[0]?.children[1]?.level,  // 学业水平
          ],
          B: [    // 阅读与鉴赏
            item?.xueYe?.level?.[0]?.children?.[1]?.children[0]?.level,  // 过程表现
            item?.xueYe?.level?.[0]?.children?.[1]?.children[1]?.level,  // 学业水平
          ],
          C: [    // 表达与交流
            item?.xueYe?.level?.[0]?.children?.[2]?.children[0]?.level,  // 过程表现
            item?.xueYe?.level?.[0]?.children?.[2]?.children[1]?.level,  // 学业水平
          ],
          D: [    // 梳理与探究
            item?.xueYe?.level?.[0]?.children?.[3]?.children[0]?.level,  // 过程表现
            item?.xueYe?.level?.[0]?.children?.[3]?.children[1]?.level,  // 学业水平
          ],
        },
        Mathematics: {     //数学
          A: [    // 数与代数
            item?.xueYe?.level?.[1]?.children?.[0]?.children[0]?.level,  // 过程表现
            item?.xueYe?.level?.[1]?.children?.[0]?.children[1]?.level,  // 学业水平
          ],
          B: [    // 图形与几何
            item?.xueYe?.level?.[1]?.children?.[1]?.children[0]?.level,  // 过程表现
            item?.xueYe?.level?.[1]?.children?.[1]?.children[1]?.level,  // 学业水平
          ],
          C: [    // 统计与概率
            item?.xueYe?.level?.[1]?.children?.[2]?.children[0]?.level,  // 过程表现
            item?.xueYe?.level?.[1]?.children?.[2]?.children[1]?.level,  // 学业水平
          ],
          D:[     //综合与实践
            item?.xueYe?.level?.[1]?.children?.[3]?.children[0]?.level,  // 过程表现
            item?.xueYe?.level?.[1]?.children?.[3]?.children[1]?.level,  // 学业水平
          ]
        },

        English: {     //英语
          A: [    // 视听理解
            item?.xueYe?.level?.[2]?.children?.[0]?.children[0]?.level,  // 过程表现
            item?.xueYe?.level?.[2]?.children?.[0]?.children[1]?.level,  // 学业水平
          ],
          B: [    // 口头表达
            item?.xueYe?.level?.[2]?.children?.[1]?.children[0]?.level,  // 过程表现
            item?.xueYe?.level?.[2]?.children?.[1]?.children[1]?.level,  // 学业水平
          ],
          C: [    // 阅读理解
            item?.xueYe?.level?.[2]?.children?.[2]?.children[0]?.level,  // 过程表现
            item?.xueYe?.level?.[2]?.children?.[2]?.children[1]?.level,  // 学业水平
          ],
          D: [    // 书面表达
            item?.xueYe?.level?.[2]?.children?.[3]?.children[0]?.level,  // 过程表现
            item?.xueYe?.level?.[2]?.children?.[3]?.children[1]?.level,  // 学业水平
          ],
          E: [    // 综合语言实践
            item?.xueYe?.level?.[2]?.children?.[4]?.children[0]?.level,  // 过程表现
            item?.xueYe?.level?.[2]?.children?.[4]?.children[1]?.level,  // 学业水平
          ]
        },

        Science: {     //科学
          A: [    // 科学观念
            item?.xueYe?.level?.[3]?.children?.[0]?.children[0]?.level,  // 过程表现
            item?.xueYe?.level?.[3]?.children?.[0]?.children[1]?.level,  // 学业水平
          ],
          B: [    // 科学思维
            item?.xueYe?.level?.[3]?.children?.[1]?.children[0]?.level,  // 过程表现
            item?.xueYe?.level?.[3]?.children?.[1]?.children[1]?.level,  // 学业水平
          ],
          C: [    // 探究实践
            item?.xueYe?.level?.[3]?.children?.[2]?.children[0]?.level,  // 过程表现
            item?.xueYe?.level?.[3]?.children?.[2]?.children[1]?.level,  // 学业水平
          ],
        },
        Quality: {     //素养课程
          A:[
            item?.xueYe?.level?.[4]?.children[0]?.level,  // 过程表现
            item?.xueYe?.level?.[4]?.children[1]?.level,  // 学业水平
          ]
        },


        // 第三页
        YDXG:item?.shenXin?.star?.[0]?.score,  //运动习惯
        RSZW:item?.shenXin?.star?.[1]?.score,  //认识自我
        TBJW:item?.shenXin?.star?.[2]?.score,  //同伴交往
        QXGL:item?.shenXin?.star?.[3]?.score,  //情绪管理

        height:item?.shenXin?.input?.[0]?.score,  //身高
        weight:item?.shenXin?.input?.[1]?.score,  //体重
        BMI:item?.shenXin?.input?.[2]?.score,  //BMI指数（正常范围18.5-23.9）
        FHL:item?.shenXin?.input?.[3]?.score, //肺活量
        VSL:item?.shenXin?.input?.[4]?.score,  //左眼视力
        VSR:item?.shenXin?.input?.[5]?.score,  //右眼视力

        Sport:{   // 体育健康
          A: [
            item?.shenXin?.level?.[0]?.children?.[0]?.level,  // 过程表现
            item?.shenXin?.level?.[0]?.children?.[1]?.level,  // 学业水平
          ]
        },
        Physique:{   // 体质监控监测
          A: [
            item?.shenXin?.level?.[1]?.children?.[0]?.level,  // 过程表现
            item?.shenXin?.level?.[1]?.children?.[1]?.level,  // 学业水平
          ]
        },

        Art:{
          music:[     // 艺术音乐
            item?.yiShu?.level?.[0]?.children?.[0]?.level,  // 过程表现
            item?.yiShu?.level?.[0]?.children?.[1]?.level,  // 学业水平
          ],
          painting:[     // 艺术美术
            item?.yiShu?.level?.[1]?.children?.[0]?.level,  // 过程表现
            item?.yiShu?.level?.[1]?.children?.[1]?.level,  // 学业水平
          ]
        },


        // 体育
        TY:item?.honors?.TY?.length ? item.honors.TY.splice(0,2)?.map(part=>`在${part.name}比赛中，荣获${this.conversionReward(part.level)}奖励`).join('；') :  item?.shenXin?.comment[0].score,
        // 音乐
        YY:item?.honors?.YY?.length ? item.honors.YY.splice(0,2)?.map(part=>`在${part.name}比赛中，荣获${this.conversionReward(part.level)}奖励`).join('；') :  item?.yiShu?.comment[0].children.map(part=>part.score).join(';'),
        // 美术
        MS:item?.honors?.MS?.length ? item.honors.MS.splice(0,2)?.map(part=>`在${part.name}比赛中，荣获${this.conversionReward(part.level)}奖励`).join('；') :  item?.yiShu?.comment[1].children.map(part=>part.score).join(';'),

        // // 体育
        // TY:item?.comment?.ty,
        // // 音乐
        // YY:item?.comment?.yy,
        // // 美术
        // MS:item?.comment?.ms,


        work:[   // 劳动
          item?.laoDong?.level?.[0]?.children?.[0]?.level,  // 过程表现
          item?.laoDong?.level?.[0]?.children?.[1]?.level,  // 学业水平
        ],

        practice:[   // 综合实践活动
          item?.laoDong?.level?.[1]?.children?.[0]?.activities,  // 第一次活动名称
          item?.laoDong?.level?.[1]?.children?.[0]?.level,  // 第一次活动值
          item?.laoDong?.level?.[1]?.children?.[1]?.activities,  // 第二次活动名称
          item?.laoDong?.level?.[1]?.children?.[1]?.level,  // 第二次活动值
        ],
        // 综合
        ZHSJ:item?.comment?.zh,   // 综合实践

        XXKJ:[   // 信息科技
          item?.laoDong?.level?.[2]?.children?.[0]?.level,  // 过程表现
          item?.laoDong?.level?.[2]?.children?.[1]?.level,  // 学业水平
        ],
        LDSJZ:[   // 劳动实践周
          item?.laoDong?.level?.[3]?.children?.[0]?.level,  // 过程表现
          item?.laoDong?.level?.[3]?.children?.[1]?.level,  // 学业水平
        ],


        // 第四页
        five:item?.five,   // 五育图
        parent:item?.parentComment,  // 家长评价
        teacher:item?.masterComment,  // 教师评价

        attendance: item?.attendance,  // 考勤

        principal: item?.principal,  // 校长,
        teaching: item?.teaching, // 教导主任
        master: item?.master?.real_name, // 教师

        masterSign: item?.master?.sign || item?.master?.seal, // 教师签名
      }
    },
    // 绘制五角星
    drawStar(ctx, x, y, R, lineWidth,fillColor) {
        // 计算五角星的各个顶点坐标
        const pointA = {
          x: x,
          y: y - R * Math.cos(Math.PI / 10)
        };
        const pointB = {
          x: x + R * Math.sin((Math.PI / 10 ) * 2),
          y: y - R * Math.cos(Math.PI / 10)
        };
        const pointC = {
          x: x + 0.4 * R * Math.sin((Math.PI / 10)),
          y: y + R * Math.cos((Math.PI / 10) * 7)
        };
        const pointD = {
          x: x + R * Math.sin(Math.PI / 10 ),
          y: y - 1.2 * R * Math.cos((Math.PI / 10 )),
        };
        const pointE = {
          x: x + R * Math.sin((Math.PI / 10 ) * 1.6),
          y: y + R * Math.cos((Math.PI / 10) * 7)
        };

        // 开始绘制五角星
        ctx.beginPath();
        ctx.moveTo(pointA.x, pointA.y);
        ctx.lineTo(pointB.x, pointB.y);
        ctx.lineTo(pointC.x, pointC.y);
        ctx.lineTo(pointD.x, pointD.y);
        ctx.lineTo(pointE.x, pointE.y);
        ctx.lineTo(pointA.x, pointA.y);
        ctx.fillStyle = fillColor;
        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = fillColor;
        ctx.fill();
        ctx.stroke();
      },
    // 绘制雷达图
    async drawRadar(ctx, five, height) {
      let that = this;
      let loadEcharts = function(five){
        return new Promise((resolve)=>{
          let data = five.map(item=>item.nowScore); // 示例数据
          let maxValue = Math.max.apply(null,data);
          // console.log('maxValue:',maxValue)
          let labels = five.map(item=>{
            return {
              name:item.title,
              // max:maxValue
              max:item.maxScore
            }
          }); // 示例标签
          let divElement = document.createElement('div')
          divElement.style.width = `${that.fontSize * 20}px`
          divElement.style.height = `${that.fontSize * 14}px`
          let myChart = echarts.init(divElement);
          let option = {
            tooltip: {
              show:true
            },
            radar: {
              radius: '60%',
              center: ['40%', '55%'],
              splitNumber: 10,
              nameGap: '10',
              name: {
                textStyle: {
                  color: '#646464',
                  fontSize:that.fontSize * 0.8
                }
              },
              axisLine: {
                lineStyle: {
                  color: '#076CFF'
                }
              },
              splitLine: {
                lineStyle: {
                  width: 0,
                }
              },
              splitArea: {
                areaStyle: {
                  color: [ 'rgb(125,200,242)', 'rgb(137,205,244)','rgb(143,210,246)','rgb(155,216,248)','rgb(167,220,250)','rgb(186,227,252)' ,'rgb(200,230,253)','rgb(216,235,254)','rgb(233,245,255)','#fff'],
                }
              },
              indicator: labels,
            },
            series: [{
              type: 'radar',
              symbolSize: 0,
              areaStyle: {
                normal: {
                  width: 0,
                  opacity: 0.4,
                },
              },
              lineStyle: {
                normal: {
                  width:0,
                },
              },
              data: [{
                itemStyle: {
                  normal: {
                    color: '#0291FF',
                  },
                },
                value: data,
              }]
            }]
          };
          myChart.setOption(option)
          myChart.on('finished',async ()=>{
            let urlImg = myChart.getDataURL({
              type:'png',
              pixelRatio:2,
            })
            let image = await that.onDragImage(urlImg)
            resolve(image)
          })
        })
      }
      let image = await loadEcharts(five)
      if(image) ctx.drawImage(image, this.fontSize * 50, this.fontSize * 6 + height, image.width /2, image.height/2);
    },

    // 绘制正面
    drawFront(data,ctx ,grade ,type) {

      ctx.fillText(data['className'], this.fontSize * 57.5, this.fontSize * 23);  //    绘制班级
      if(grade === 1 || (grade === 2 && type === 1)){
        ctx.fillText(data['grade'], this.fontSize * 51.5, this.fontSize * 28.8);  //    绘制年级
        ctx.fillText(data['semester'], this.fontSize * 56.8, this.fontSize * 28.8);  //    绘制学期
      }
      if(grade === 2 && type === 2){
        ctx.fillText(data['grade'],  this.fontSize * 52.2, this.fontSize * 28.8);  //    绘制年级
        ctx.fillText(data['semester'], this.fontSize * 57.8, this.fontSize * 28.8);  //    绘制学期
      }
      if([1,2].includes(grade)){
        ctx.fillText(data['real_name'], this.fontSize * 57.5 - data['real_name'].length * 10,  this.fontSize * 25.6);  //    绘制名字
      }
      if([3,4,5,6].includes(grade)){
        ctx.fillText(data['real_name'], this.fontSize * 57.5 - data['real_name'].length * 10,  this.fontSize * 25.7);  //    绘制名字
        ctx.fillText(data['grade'], this.fontSize * 52.2, this.fontSize * 28.8);  //    绘制年级
        ctx.fillText(data['semester'], this.fontSize * 57.8, this.fontSize * 28.8);  //    绘制学期
      }



      let  p = 40, m = 13;
      // 国家情怀
      // data.JGQH = 5
      if(data.JGQH){
        let x = this.fontSize * 14  - data.JGQH * m;
        for ( let i = 0 ; i < data.JGQH ; i++ ) {
          // 绘制五角星
          this.drawStar(ctx, x + i * p, this.fontSize * 8.2, 60, 1, 'red');
        }
      }else{
        ctx.fillText('-', this.fontSize * 14.5, this.fontSize * 7.5);
      }
      // 责任担当
      // data.ZRDD = 3
      if(data.ZRDD){
        let x = this.fontSize * 30  - data.ZRDD * m;
        for ( let i = 0 ; i < data.ZRDD ; i++ ) {
          // 绘制五角星
          this.drawStar(ctx, x + i * p, this.fontSize * 8.2, 60, 1, 'red');
        }
      }else{
        ctx.fillText('-', this.fontSize * 30.5, this.fontSize * 7.5);
      }

      //遵规守纪
      // data.ZGSJ = 5
      if(data.ZGSJ){
        let x = this.fontSize * 14  - data.ZGSJ * m;
        for ( let i = 0 ; i < data.ZGSJ ; i++ ) {
          // 绘制五角星
          this.drawStar(ctx, x + i * p, this.fontSize * 10.3, 60, 1, 'red');
        }
      }else{
        ctx.fillText('-', this.fontSize * 14.5, this.fontSize * 9.6);
      }

      // 文明礼貌
      // data.WMLM = 5
      if(data.WMLM){
        let x = this.fontSize * 30  - data.WMLM * m;
        for ( let i = 0 ; i < data.WMLM ; i++ ) {
          // 绘制五角星
          this.drawStar(ctx, x + i * p, this.fontSize * 10.3, 60, 1, 'red');
        }
      }else{
        ctx.fillText('-', this.fontSize * 30.5, this.fontSize * 9.6);
      }

      // 诚信友爱
      // data.CXYA = 5
      if(data.CXYA){
        let x =  this.fontSize * 14  - data.CXYA * m;
        for ( let i = 0 ; i < data.CXYA ; i++ ) {
          // 绘制五角星
          this.drawStar(ctx, x + i * p, this.fontSize * 12.4, 60, 1, 'red');
        }
      }else{
        ctx.fillText('-', this.fontSize * 14.5, this.fontSize * 11.7);
      }

      ctx.fillStyle = 'black';
      ctx.textAlign = 'center';
      ctx.fillText(data['DDFZ'].XXTD || '-', this.fontSize * 12.5, this.fontSize * 16);  //   道德法治-学习态度
      ctx.fillText(data['DDFZ'].RZSP || '-', this.fontSize * 19.5, this.fontSize * 16);  //   道德法治-认知水平
      ctx.fillText(data['DDFZ'].SJNL || '-', this.fontSize * 29, this.fontSize * 16);  //   道德法治-实践能力

      ctx.fillText(data['DFKC'].GCBX || '-', this.fontSize * 16, this.fontSize * 20.5);  //   地方课程-过程表现
      ctx.fillText(data['DFKC'].CGSP || '-', this.fontSize * 29, this.fontSize * 20.5);  //   地方课程-成果水平

      // 1上、1下、2上
      if([1,2].includes(grade)){
        // 语文 - 识字与写字
        ctx.fillText(data['Chinese'].A[0] || '-', this.fontSize * 23, this.fontSize * 27.2);  //   语文-过程表现
        ctx.fillText(data['Chinese'].A[1] || '-', this.fontSize * 31, this.fontSize * 27.2);  //   语文-成果水平
        // 语文 - 阅读与鉴赏
        ctx.fillText(data['Chinese'].B[0] || '-', this.fontSize * 23, this.fontSize * 29.5);  //   语文-过程表现
        ctx.fillText(data['Chinese'].B[1] || '-', this.fontSize * 31, this.fontSize * 29.5);  //   语文-成果水平
        // 语文 - 表达与交流
        ctx.fillText(data['Chinese'].C[0] || '-', this.fontSize * 23, this.fontSize * 31.8);  //   语文-过程表现
        ctx.fillText(data['Chinese'].C[1] || '-', this.fontSize * 31, this.fontSize * 31.8);  //   语文-成果水平
        // 语文 - 梳理与探究
        ctx.fillText(data['Chinese'].D[0] || '-', this.fontSize * 23, this.fontSize * 34);  //   语文-过程表现
        ctx.fillText(data['Chinese'].D[1] || '-', this.fontSize * 31, this.fontSize * 34);  //   语文-成果水平


        // 数学 - 数与代数
          ctx.fillText(data['Mathematics'].A[0] || '-', this.fontSize * 23, this.fontSize * 36.2 );  //   数学-过程表现
        ctx.fillText(data['Mathematics'].A[1] || '-', this.fontSize * 31, this.fontSize * 36.2 );  //   数学-成果水平
        // 数学 - 图形与几何
        ctx.fillText(data['Mathematics'].B[0] || '-', this.fontSize * 23, this.fontSize * 38.5);  //   数学-过程表现
        ctx.fillText(data['Mathematics'].B[1] || '-', this.fontSize * 31, this.fontSize * 38.5);  //   数学-成果水平

        // 2下
        if(grade === 2 && type === 2){
          // 数学 - 综合与实践
          ctx.fillText(data['Mathematics'].D[0] || '-', this.fontSize * 23, this.fontSize * 40.7);  //   数学-过程表现
          ctx.fillText(data['Mathematics'].D[1] || '-', this.fontSize * 31, this.fontSize * 40.7);  //   数学-成果水平
          // 数学 - 统计与概率
          ctx.fillText(data['Mathematics'].C[0] || '-', this.fontSize * 23, this.fontSize * 42.9);  //   数学-过程表现
          ctx.fillText(data['Mathematics'].C[1] || '-', this.fontSize * 31, this.fontSize * 42.9);  //   数学-成果水平

          // 科学 - 科学观念
          ctx.fillText(data['Science'].A[0] || '-', this.fontSize * 23, this.fontSize * 45.1 );  //   科学-过程表现
          ctx.fillText(data['Science'].A[1] || '-', this.fontSize * 31, this.fontSize * 45.1 );  //   科学-成果水平
          // 科学 - 科学思维
          ctx.fillText(data['Science'].B[0] || '-', this.fontSize * 23, this.fontSize * 47.2);  //   科学-过程表现
          ctx.fillText(data['Science'].B[1] || '-', this.fontSize * 31, this.fontSize * 47.2);  //   科学-成果水平
          // 科学 - 探究实践
          ctx.fillText(data['Science'].C[0] || '-', this.fontSize * 23, this.fontSize * 49.5);  //   科学-过程表现
          ctx.fillText(data['Science'].C[1] || '-', this.fontSize * 31, this.fontSize * 49.5);  //   科学-成果水平

          // 素养课程
          ctx.fillText(data['Quality'].A[0] || '-', this.fontSize * 23, this.fontSize * 51.6);  //   素养课程-过程表现
          ctx.fillText(data['Quality'].A[1] || '-', this.fontSize * 31, this.fontSize * 51.6);  //   素养课程-成果水平
        }else{
          // 数学 - 综合与实践
          ctx.fillText(data['Mathematics'].D[0] || '-', this.fontSize * 23, this.fontSize * 40.7);  //   数学-过程表现
          ctx.fillText(data['Mathematics'].D[1] || '-', this.fontSize * 31, this.fontSize * 40.7);  //   数学-成果水平

          // 科学 - 科学观念
          ctx.fillText(data['Science'].A[0] || '-', this.fontSize * 23, this.fontSize * 42.9);  //   科学-过程表现
          ctx.fillText(data['Science'].A[1] || '-', this.fontSize * 31, this.fontSize * 42.9);  //   科学-成果水平
          // 科学 - 科学思维
          ctx.fillText(data['Science'].B[0] || '-', this.fontSize * 23, this.fontSize * 45.1);  //   科学-过程表现
          ctx.fillText(data['Science'].B[1] || '-', this.fontSize * 31, this.fontSize * 45.1);  //   科学-成果水平
          // 科学 - 探究实践
          ctx.fillText(data['Science'].C[0] || '-', this.fontSize * 23, this.fontSize * 47.3);  //   科学-过程表现
          ctx.fillText(data['Science'].C[1] || '-', this.fontSize * 31, this.fontSize * 47.3);  //   科学-成果水平

          // 素养课程
          ctx.fillText(data['Quality'].A[0] || '-', this.fontSize * 23, this.fontSize * 49.4);  //   素养课程-过程表现
          ctx.fillText(data['Quality'].A[1] || '-', this.fontSize * 31, this.fontSize * 49.4);  //   素养课程-成果水平
        }
      }

      // 3-6年级
      if([3,4,5,6].includes(grade)){
        // 语文 - 识字与写字
        ctx.fillText(data['Chinese'].A[0] || '-', this.fontSize * 23, this.fontSize * 26.2);  //   语文-过程表现
        ctx.fillText(data['Chinese'].A[1] || '-', this.fontSize * 31, this.fontSize * 26.2);  //   语文-成果水平
        // 语文 - 阅读与鉴赏
        ctx.fillText(data['Chinese'].B[0] || '-', this.fontSize * 23, this.fontSize * 27.8);  //   语文-过程表现
        ctx.fillText(data['Chinese'].B[1] || '-', this.fontSize * 31, this.fontSize * 27.8);  //   语文-成果水平
        // 语文 - 表达与交流
        ctx.fillText(data['Chinese'].C[0] || '-', this.fontSize * 23, this.fontSize * 29.3);  //   语文-过程表现
        ctx.fillText(data['Chinese'].C[1] || '-', this.fontSize * 31, this.fontSize * 29.3);  //   语文-成果水平
        // 语文 - 梳理与探究
        ctx.fillText(data['Chinese'].D[0] || '-', this.fontSize * 23, this.fontSize * 30.9);  //   语文-过程表现
        ctx.fillText(data['Chinese'].D[1] || '-', this.fontSize * 31, this.fontSize * 30.9);  //   语文-成果水平

        // 数学 - 数与代数
        ctx.fillText(data['Mathematics'].A[0] || '-', this.fontSize * 23, this.fontSize * 32.4);  //   数学-过程表现
        ctx.fillText(data['Mathematics'].A[1] || '-', this.fontSize * 31, this.fontSize * 32.4);  //   数学-成果水平
        // 数学 - 图形与几何
        ctx.fillText(data['Mathematics'].B[0] || '-', this.fontSize * 23, this.fontSize * 33.9);  //   数学-过程表现
        ctx.fillText(data['Mathematics'].B[1] || '-', this.fontSize * 31, this.fontSize * 33.9);  //   数学-成果水平

        // 3上
        if(grade === 3 && type === 1){
          // 数学 - 综合与实践
          ctx.fillText(data['Mathematics'].D[0] || '-', this.fontSize * 23, this.fontSize * 35.4);  //   数学-过程表现
          ctx.fillText(data['Mathematics'].D[1] || '-', this.fontSize * 31, this.fontSize * 35.4);  //   数学-成果水平
          // 英语 - 视听理解
          ctx.fillText(data['English'].A[0] || '-', this.fontSize * 23, this.fontSize * 37);  //   英语-过程表现
          ctx.fillText(data['English'].A[1] || '-', this.fontSize * 31, this.fontSize * 37);  //   英语-成果水平
          // 英语 - 口头表达
          ctx.fillText(data['English'].B[0] || '-', this.fontSize * 23, this.fontSize * 38.5);  //   英语-过程表现
          ctx.fillText(data['English'].B[1] || '-', this.fontSize * 31, this.fontSize * 38.5);  //   英语-成果水平
          // 英语 - 阅读理解
          ctx.fillText(data['English'].C[0] || '-', this.fontSize * 23, this.fontSize * 40);  //   英语-过程表现
          ctx.fillText(data['English'].C[1] || '-', this.fontSize * 31, this.fontSize * 40);  //   英语-成果水平
          // 英语 - 书面表达
          ctx.fillText(data['English'].D[0] || '-', this.fontSize * 23, this.fontSize * 41.6);  //   英语-过程表现
          ctx.fillText(data['English'].D[1] || '-', this.fontSize * 31, this.fontSize * 41.6);  //   英语-成果水平
          // 英语 - 综合语言实践
          ctx.fillText(data['English'].E[0] || '-', this.fontSize * 23, this.fontSize * 43.1);  //   英语-过程表现
          ctx.fillText(data['English'].E[1] || '-', this.fontSize * 31, this.fontSize * 43.1);  //   英语-成果水平

          // 科学 - 科学观念
          ctx.fillText(data['Science'].A[0] || '-', this.fontSize * 23, this.fontSize * 44.6);  //   科学-过程表现
          ctx.fillText(data['Science'].A[1] || '-', this.fontSize * 31, this.fontSize * 44.6);  //   科学-成果水平
          // 科学 - 科学思维
          ctx.fillText(data['Science'].B[0] || '-', this.fontSize * 23, this.fontSize * 46.2);  //   科学-过程表现
          ctx.fillText(data['Science'].B[1] || '-', this.fontSize * 31, this.fontSize * 46.2);  //   科学-成果水平
          // 科学 - 探究实践
          ctx.fillText(data['Science'].C[0] || '-', this.fontSize * 23, this.fontSize * 47.7);  //   科学-过程表现
          ctx.fillText(data['Science'].C[1] || '-', this.fontSize * 31, this.fontSize * 47.7);  //   科学-成果水平

          // 素养课程
          ctx.fillText(data['Quality'].A[0] || '-', this.fontSize * 23, this.fontSize * 49.2);  //   素养课程-过程表现
          ctx.fillText(data['Quality'].A[1] || '-', this.fontSize * 31, this.fontSize * 49.2);  //   素养课程-成果水平
        }else{
          // 数学 - 统计与概率
          ctx.fillText(data['Mathematics'].C[0] || '-', this.fontSize * 23, this.fontSize * 35.4);  //   数学-过程表现
          ctx.fillText(data['Mathematics'].C[1] || '-', this.fontSize * 31, this.fontSize * 35.4);  //   数学-成果水平
          // 数学 - 综合与实践
          ctx.fillText(data['Mathematics'].D[0] || '-', this.fontSize * 23, this.fontSize * 37);  //   数学-过程表现
          ctx.fillText(data['Mathematics'].D[1] || '-', this.fontSize * 31, this.fontSize * 37);  //   数学-成果水平

          // 英语 - 视听理解
          ctx.fillText(data['English'].A[0] || '-', this.fontSize * 23, this.fontSize * 38.5);  //   英语-过程表现
          ctx.fillText(data['English'].A[1] || '-', this.fontSize * 31, this.fontSize * 38.5);  //   英语-成果水平
          // 英语 - 口头表达
          ctx.fillText(data['English'].B[0] || '-', this.fontSize * 23, this.fontSize * 40);  //   英语-过程表现
          ctx.fillText(data['English'].B[1] || '-', this.fontSize * 31, this.fontSize * 40);  //   英语-成果水平
          // 英语 - 阅读理解
          ctx.fillText(data['English'].C[0] || '-', this.fontSize * 23, this.fontSize * 41.6);  //   英语-过程表现
          ctx.fillText(data['English'].C[1] || '-', this.fontSize * 31, this.fontSize * 41.6);  //   英语-成果水平
          // 英语 - 书面表达
          ctx.fillText(data['English'].D[0] || '-', this.fontSize * 23, this.fontSize * 43.1);  //   英语-过程表现
          ctx.fillText(data['English'].D[1] || '-', this.fontSize * 31, this.fontSize * 43.1);  //   英语-成果水平
          // 英语 - 综合语言实践
          ctx.fillText(data['English'].E[0] || '-', this.fontSize * 23, this.fontSize * 44.6);  //   英语-过程表现
          ctx.fillText(data['English'].E[1] || '-', this.fontSize * 31, this.fontSize * 44.6);  //   英语-成果水平

          // 科学 - 科学观念
          ctx.fillText(data['Science'].A[0] || '-', this.fontSize * 23, this.fontSize * 46.2);  //   科学-过程表现
          ctx.fillText(data['Science'].A[1] || '-', this.fontSize * 31, this.fontSize * 46.2);  //   科学-成果水平
          // 科学 - 科学思维
          ctx.fillText(data['Science'].B[0] || '-', this.fontSize * 23, this.fontSize * 47.7);  //   科学-过程表现
          ctx.fillText(data['Science'].B[1] || '-', this.fontSize * 31, this.fontSize * 47.7);  //   科学-成果水平
          // 科学 - 探究实践
          ctx.fillText(data['Science'].C[0] || '-', this.fontSize * 23, this.fontSize * 49.3);  //   科学-过程表现
          ctx.fillText(data['Science'].C[1] || '-', this.fontSize * 31, this.fontSize * 49.3);  //   科学-成果水平

          // 素养课程
          ctx.fillText(data['Quality'].A[0] || '-', this.fontSize * 23, this.fontSize * 50.8);  //   素养课程-过程表现
          ctx.fillText(data['Quality'].A[1] || '-', this.fontSize * 31, this.fontSize * 50.8);  //   素养课程-成果水平
        }
      }

    },

    // 绘制背面
    async drawBack(data,ctx,grade,type,height){
      // 渔健
      let  p = 40, m = 13;
      //   运动习惯
      // data.YDXG = 5
      if(data.YDXG){
        let x = this.fontSize * 14  - data.YDXG * m;
        for ( let i = 0 ; i < data.YDXG ; i++ ) {
          // 绘制五角星
          this.drawStar(ctx, x + i * p, this.fontSize * 8.6 + height, 60, 1, 'red');
        }
      }else{
        ctx.fillText('-', this.fontSize * 14.5, this.fontSize * 7.9 + height);
      }

      //    认识自我
      // data.RSZW = 3
      if(data.RSZW){
        let x = this.fontSize * 30  - data.RSZW * m;
        for ( let i = 0 ; i < data.RSZW ; i++ ) {
          // 绘制五角星
          this.drawStar(ctx, x + i * p, this.fontSize * 8.6 + height, 60, 1, 'red');
        }
      }else{
        ctx.fillText('-', this.fontSize * 30.5, this.fontSize * 7.9 + height);
      }

      //  同伴交往
      // data.TBJW = 3
      if(data.TBJW){
        let x = this.fontSize * 14  - data.TBJW * m;
        for ( let i = 0 ; i < data.TBJW ; i++ ) {
          // 绘制五角星
          this.drawStar(ctx, x + i * p, this.fontSize * 10.4 + height, 60, 1, 'red');
        }
      }else{
        ctx.fillText('-',  this.fontSize * 14.5, this.fontSize * 9.7 + height);
      }

      //  情绪管理
      // data.QXGL = 5
      if(data.QXGL){
        let x = this.fontSize * 30  - data.QXGL * m;
        for ( let i = 0 ; i < data.QXGL ; i++ ) {
          // 绘制五角星
          this.drawStar(ctx, x + i * p, this.fontSize * 10.4 + height, 60, 1, 'red');
        }
      }else{
        ctx.fillText('-',this.fontSize * 30.5, this.fontSize * 9.7 + height);
      }
      ctx.fillStyle = 'black';

      // 身高
      ctx.fillText(data['height']?((data['height']) / 100 ).toFixed(2): '-', this.fontSize * 6, this.fontSize * 14.1 + height);
      // 体重
      ctx.fillText((data['weight']) || '-', this.fontSize * 11, this.fontSize * 14.1 + height);
      // BMI
      ctx.fillText(data['BMI'] || '-', this.fontSize * 18, this.fontSize * 14.1 + height);
      // 肺活量
      ctx.fillText(data['FHL'] || '-', this.fontSize * 24, this.fontSize * 14.1 + height);
      // 左眼视力
      ctx.fillText(data['VSL'] || '-', this.fontSize * 29.5, this.fontSize * 14.1 + height);
      // 右眼视力
      ctx.fillText(data['VSR'] || '-', this.fontSize * 33, this.fontSize * 14.1 + height);
      // 体育健康
      ctx.fillText(data['Sport'].A[0] || '-', this.fontSize * 12, this.fontSize * 17.8 + height);
      ctx.fillText(data['Sport'].A[1] || '-', this.fontSize * 16.5, this.fontSize * 17.8 + height);
      // 体质监控监测
      ctx.fillText(data['Physique'].A[0] || '-', this.fontSize * 27.3, this.fontSize * 17.8 + height);
      ctx.fillText(data['Physique'].A[1] || '-', this.fontSize * 32, this.fontSize * 17.8 + height);
      // 体育
      // ctx.fillText(data.TY || '-', this.fontSize * 22, this.fontSize * 22.3 + height);

      let TY_S = 0.8;
      let TY_SW = this.fontSize * 22
      let TY_n = 40

      ctx.font = `${this.fontSize * 0.6}px Arial bolder`;
      // 体育
      let TY = this.splitText(data.TY || '-', TY_n);
      let TY_SH = this.fontSize * 21.4 + height
      for ( const i in TY ) {
        ctx.fillText( TY[i], TY_SW + ((TY[i].length - TY_n) / 2 * this.fontSize * 0.6), TY_SH + i * this.fontSize + i * TY_S);
      }
      ctx.font = `${this.fontSize}px Arial bolder`;

      // 渔艺
      if([1,2].includes(grade)){
        // 艺术音乐
        ctx.fillText(data['Art'].music[0] || '-', this.fontSize * 15.5, this.fontSize * 29 + height);  //   艺术音乐-过程表现
        ctx.fillText(data['Art'].music[1] || '-', this.fontSize * 28.7, this.fontSize * 29 + height);  //   艺术音乐-成果水平
        // 艺术美术
        ctx.fillText(data['Art'].painting[0] || '-', this.fontSize * 15.5, this.fontSize * 30.8 + height);  //   艺术美术-过程表现
        ctx.fillText(data['Art'].painting[1] || '-', this.fontSize * 28.7, this.fontSize * 30.8 + height);  //   艺术美术-成果水平
        // 音乐
        // ctx.fillText(data.YY || '-', this.fontSize * 22, this.fontSize * 35.1 + height);  //   音乐
        // ctx.fillText(data.MS || '-', this.fontSize * 22, this.fontSize * 38.1 + height);  //   美术


        let YY_S = 0.8;
        let YY_SW = this.fontSize * 22
        let YY_n = 40

        ctx.font = `${this.fontSize * 0.6}px Arial bolder`;
        // 音乐
        let YY = this.splitText(data.YY || '-', YY_n);
        let YY_SH = this.fontSize * 34.2 + height
        for ( const i in YY ) {
          ctx.fillText( YY[i], YY_SW + ((YY[i].length - YY_n) / 2 * this.fontSize * 0.6), YY_SH + i * this.fontSize + i * YY_S);
        }
        let MS = this.splitText(data.MS || '-', YY_n);
        let MS_SH = this.fontSize * 37.2 + height
        for ( const i in MS ) {
          ctx.fillText( MS[i], YY_SW + ((MS[i].length - YY_n) / 2 * this.fontSize * 0.6), MS_SH + i * this.fontSize + i * YY_S);
        }
        ctx.font = `${this.fontSize}px Arial bolder`;


        //渔行
        // 劳动
        ctx.fillText(data['work'][0] || '-', this.fontSize * 15.5, this.fontSize * 44.7 + height);  //   劳动-过程表现
        ctx.fillText(data['work'][1] || '-', this.fontSize * 28.7, this.fontSize * 44.7 + height);  //   劳动-成果水平
        // 综合实践活动
        ctx.font = `${this.fontSize * 0.6}px Arial bolder`;
        ctx.fillText(data['practice'][0] || '-', this.fontSize * 15.5, this.fontSize * 46.0 + height);  //   综合实践活动-过程表现
        ctx.fillText(data['practice'][1] || '-', this.fontSize * 28.7, this.fontSize * 46.0 + height);  //   综合实践活动-成果水平
        ctx.fillText(data['practice'][2] || '-', this.fontSize * 15.5, this.fontSize * 47.3 + height);  //   综合实践活动-过程表现
        ctx.fillText(data['practice'][3] || '-', this.fontSize * 28.7, this.fontSize * 47.3 + height);  //   综合实践活动-成果水平
        ctx.font = `${this.fontSize}px Arial bolder`;
        // 综合
        // ctx.fillText(data.zh || '-', this.fontSize * 15.5, this.fontSize * 47.5 + height);  //   综合-过程表现
        // 劳动实践周
        ctx.fillText(data['LDSJZ'][0] || '-', this.fontSize * 12.5, this.fontSize * 50.8 + height);  //   劳动实践周-过程表现
        ctx.fillText(data['LDSJZ'][1] || '-', this.fontSize * 19, this.fontSize * 50.8 + height);  //   劳动实践周-成果水平

        console.log('11111',data['attendance'],(data['attendance'].sick + '').length)
        let arrSick = '-'
        if((data['attendance']?.sick + '').length >0){
          console.log("111111111111111111111111111111111")
          arrSick = data['attendance']?.sick
        }
        let arrThing = '-'
        if((data['attendance']?.thing + '').length >0){
          console.log("111111111111111111111111111111111")
          arrThing = data['attendance']?.thing
        }
        if(type === 1){
          // 考勤

          ctx.fillText(data['attendance']?.total || '-',  this.fontSize * 48.8, this.fontSize * 42.9 + height);         // 总数
          ctx.fillText(data['attendance']?.actual || '-', this.fontSize * 55, this.fontSize * 42.9 + height  );       // 实际
          ctx.fillText(arrSick,   this.fontSize * 63.3, this.fontSize * 42.9 + height);      // 病假
          ctx.fillText(arrThing,  this.fontSize * 69.6, this.fontSize * 42.9 + height);        // 事假
        }else{
          // 考勤
          ctx.fillText(data['attendance']?.total || '-',  this.fontSize * 48.8, this.fontSize * 43.7 + height);         // 总数
          ctx.fillText(data['attendance']?.actual || '-', this.fontSize * 55,   this.fontSize * 43.7 + height  );       // 实际
          ctx.fillText(arrSick,   this.fontSize * 63.3, this.fontSize * 43.7 + height);      // 病假
          ctx.fillText(arrThing,  this.fontSize * 69.6, this.fontSize * 43.7 + height );        // 事假
        }

        // 校长
        // ctx.fillText(data['principal'] || '-', 705, height+718);
        if(data['principal']){
          let sign = await this.onDragImage(data['principal'],this.fontSize * 4.8,this.fontSize * 2.5);
          if(sign) ctx.drawImage(sign,  this.fontSize * 46.6, this.fontSize * 48.3 + height - sign.height /2, sign.width, sign.height);
        }


        // 教导主任
        // ctx.fillText(data['teaching'] || '-', 885, height+718);
        if(data['teaching']){
          let sign = await this.onDragImage(data['teaching'],this.fontSize * 4.6,this.fontSize * 2.1);
          if(sign) ctx.drawImage(sign, this.fontSize * 58, this.fontSize * 48.3 + height - sign.height /2, sign.width, sign.height);
        }

        // 班主任
        if(data['masterSign']){
          // 班主任签名
          let sign = await this.onDragImage(data['masterSign'],this.fontSize * 4.6,this.fontSize * 2.1);
          if(sign) ctx.drawImage(sign, this.fontSize * 68.4, this.fontSize * 48.3 + height - sign.height /2, sign.width, sign.height);
        }else{
          ctx.fillText(data['master'] || '-', this.fontSize * 70, this.fontSize * 48.7 + height);
        }
      }else{
        // 艺术音乐
        ctx.fillText(data['Art'].music[0] || '-', this.fontSize * 15.5, this.fontSize * 28 + height);  //   艺术音乐-过程表现
        ctx.fillText(data['Art'].music[1] || '-', this.fontSize * 28.7, this.fontSize * 28 + height);  //   艺术音乐-成果水平
        // 艺术美术
        ctx.fillText(data['Art'].painting[0] || '-', this.fontSize * 15.5, this.fontSize * 29.8 + height);  //   艺术美术-过程表现
        ctx.fillText(data['Art'].painting[1] || '-', this.fontSize * 28.7, this.fontSize * 29.8 + height);  //   艺术美术-成果水平
        // 音乐
        // ctx.fillText(data.YY || '-', this.fontSize * 22, this.fontSize * 33.9 + height);  //   音乐
        // ctx.fillText(data.MS || '-', this.fontSize * 22, this.fontSize * 36.8 + height);  //   美术

        let YY_S = 0.8;
        let YY_SW = this.fontSize * 22
        let YY_n = 40

        ctx.font = `${this.fontSize * 0.6}px Arial bolder`;
        // 音乐
        let YY = this.splitText(data.YY || '-', YY_n);
        let YY_SH = this.fontSize * 33.4 + height
        for ( const i in YY ) {
          ctx.fillText( YY[i], YY_SW + ((YY[i].length - YY_n) / 2 * this.fontSize * 0.6), YY_SH + i * this.fontSize + i * YY_S);
        }
        let MS = this.splitText(data.MS || '-', YY_n);
        let MS_SH = this.fontSize * 36.3 + height
        for ( const i in MS ) {
          ctx.fillText( MS[i], YY_SW + ((MS[i].length - YY_n) / 2 * this.fontSize * 0.6), MS_SH + i * this.fontSize + i * YY_S);
        }
        ctx.font = `${this.fontSize}px Arial bolder`;

        //渔行
        // 劳动
        ctx.fillText(data['work'][0] || '-', this.fontSize * 15.5, this.fontSize * 42.6 + height);  //   劳动-过程表现
        ctx.fillText(data['work'][1] || '-', this.fontSize * 28.7, this.fontSize * 42.6 + height);  //   劳动-成果水平
        // 综合实践活动
        ctx.font = `${this.fontSize * 0.6}px Arial bolder`;
        ctx.fillText(data['practice'][0] || '-',this.fontSize * 15.5, this.fontSize * 44 + height);  //   综合实践活动-过程表现
        ctx.fillText(data['practice'][1] || '-',this.fontSize * 28.7, this.fontSize * 44 + height);  //   综合实践活动-成果水平
        ctx.fillText(data['practice'][2] || '-',this.fontSize * 15.5, this.fontSize * 45.2 + height);  //   综合实践活动-过程表现
        ctx.fillText(data['practice'][3] || '-',this.fontSize * 28.7, this.fontSize * 45.2 + height);  //   综合实践活动-成果水平
        ctx.font = `${this.fontSize}px Arial bolder`;
        // 综合
        // ctx.fillText(data.zh || '-', this.fontSize * 15.5, this.fontSize * 45.4 + height);  //   综合-过程表现
        // 信息科技
        ctx.fillText(data['XXKJ'][0] || '-', this.fontSize * 15.5, this.fontSize * 48.8 + height);  //   信息科技-过程表现
        ctx.fillText(data['XXKJ'][1] || '-', this.fontSize * 28.7, this.fontSize * 48.8 + height);  //   信息科技-成果水平
        // 劳动实践周
        ctx.fillText(data['LDSJZ'][0] || '-', this.fontSize * 13, this.fontSize * 52.4 + height);  //   劳动实践周-过程表现
        ctx.fillText(data['LDSJZ'][1] || '-', this.fontSize * 19, this.fontSize * 52.4 + height);  //   劳动实践周-成果水平

        console.log('2',data['attendance'])
        let arrSick = '-'
        if((data['attendance']?.sick + '').length >0){
          console.log("111111111111111111111111111111111")
          arrSick = data['attendance']?.sick
        }
        let arrThing = '-'
        if((data['attendance']?.thing + '').length >0){
          console.log("111111111111111111111111111111111")
          arrThing = data['attendance']?.thing
        }
        if(type === 1){
          // 考勤
          ctx.fillText(data['attendance']?.total || '-',  this.fontSize * 48.8, this.fontSize * 42.9 + height );         // 总数
          ctx.fillText(data['attendance']?.actual || '-', this.fontSize * 55, this.fontSize * 42.9 + height    );       // 实际
          ctx.fillText(arrSick,   this.fontSize * 63.3, this.fontSize * 42.9 + height  );      // 病假
          ctx.fillText(arrThing,  this.fontSize * 69.6, this.fontSize * 42.9 + height );        // 事假

          // 校长
          // ctx.fillText(data['principal'] || '-', 705, height+718);
          if(data['principal']){
            let sign = await this.onDragImage(data['principal'],this.fontSize * 4.8,this.fontSize * 2.5);
            if(sign) ctx.drawImage(sign, this.fontSize * 46.6, this.fontSize * 48.3 + height - sign.height /2, sign.width, sign.height);
          }

          // 教导主任
          // ctx.fillText(data['teaching'] || '-', 885, height+718);
          if(data['teaching']){
            let sign = await this.onDragImage(data['teaching'],this.fontSize * 4.6,this.fontSize * 2.1);
            if(sign) ctx.drawImage(sign, this.fontSize * 58, this.fontSize * 48.3 + height - sign.height /2, sign.width, sign.height);
          }

          // 班主任
          if(data['masterSign']){
            // 班主任签名
            let sign = await this.onDragImage(data['masterSign'],this.fontSize * 4.6,this.fontSize * 2.1);
            if(sign) ctx.drawImage(sign, this.fontSize * 68.4, this.fontSize * 48.3 + height - sign.height /2, sign.width, sign.height);
          }else{
            ctx.fillText(data['master'] || '-', this.fontSize * 70, this.fontSize * 48.7 + height);
          }
        }else{
          // 考勤
          ctx.fillText(data['attendance']?.total || '-',  this.fontSize * 48.5, this.fontSize * 43.4 + height );         // 总数
          ctx.fillText(data['attendance']?.actual || '-', this.fontSize * 54.7,   this.fontSize * 43.4 + height  );       // 实际
          ctx.fillText(arrSick,   this.fontSize * 63.3, this.fontSize * 43.4 + height  );      // 病假
          ctx.fillText(arrThing,  this.fontSize * 69.6, this.fontSize * 43.4 + height );        // 事假

          // 校长
          // ctx.fillText(data['principal'] || '-', 705, height+713);
          if(data['principal']){
            let sign = await this.onDragImage(data['principal'],this.fontSize * 4.8,this.fontSize * 2.5);
            if(sign) ctx.drawImage(sign, this.fontSize * 46.6, this.fontSize * 47.8 + height - sign.height /2, sign.width, sign.height);
          }

          // 教导主任
          // ctx.fillText(data['teaching'] || '-', 885, height+713);
          if(data['teaching']){
            let sign = await this.onDragImage(data['teaching'],this.fontSize * 4.6,this.fontSize * 2.1);
            if(sign) ctx.drawImage(sign, this.fontSize * 58, this.fontSize * 47.8 + height - sign.height /2, sign.width, sign.height);
          }

          // 班主任
          if(data['masterSign']){
            // 班主任签名
            let sign = await this.onDragImage(data['masterSign'],this.fontSize * 4.6,this.fontSize * 2.1);
            if(sign) ctx.drawImage(sign, this.fontSize * 68.4, this.fontSize * 47.8 + height - sign.height /2, sign.width, sign.height);
          }else{
            ctx.fillText(data['master'] || '-', this.fontSize * 70, this.fontSize * 48.4 + height);
          }
        }

      }


      let step = 20;
      let startWidth = this.fontSize * 57
      let n = 26
      if( type === 1){
        // 打印家长评语
        let parents = this.splitText(data['parent'] || '-', n);
        let starHeight = this.fontSize * 30 + height
        for ( const i in parents ) {
          ctx.fillText( parents[i], startWidth + ((parents[i].length - n) / 2 * this.fontSize), starHeight + i * this.fontSize + i * step);
        }
      }else{
        // 打印家长评语

        let parents = this.splitText(data['parent'] || '-', n);
        let starHeight = this.fontSize * 36.5 + height
        for ( const i in parents ) {
          ctx.fillText( parents[i], startWidth + ((parents[i].length - n) / 2 * this.fontSize), starHeight + i * this.fontSize + i * step);
        }
          console.log(data,'--------------------');
          //教师评价
        n = 34;
        let teachers = this.splitText(data['teacher']|| '-', n);
        console.log(teachers)
        let starTeachersHeight = this.fontSize * 21.4 + height
        ctx.font = `${this.fontSize * 0.8}px Arial bolder`;
        // for ( const i in teachers ) {
        //   ctx.fillText( teachers[i], startWidth + ((teachers[i].length - n) / 2 * (this.fontSize * 0.8)), starTeachersHeight + i * (this.fontSize*0.9) + i * step-20);
        // }
          let text = data['teacher']
          const html = text.trim().split(/\n/).map(line => `<p>${line}</p>`).join('\n')
          this.editor.setHtml(html)
          this.editorNum+=1
          this.$nextTick(()=>{
            html2canvas(document.getElementById('hidden-rich-editor')).then(function(canvas) {
              let spiHeight = canvas.height-400;
              let spiWidth = canvas.width - 1300;
              if (spiHeight>0){
                spiHeight = spiHeight/canvas.height;
              }else {
                spiHeight = 0;
              }
              if (spiWidth>0){
                spiWidth = spiWidth/canvas.width;
              }else {
                spiWidth = 0;
              }
              let imgData = canvas.toDataURL('image/png');
              let img = new Image();
              img.onload = function() {
                let scaleWidth = img.width * (1-spiWidth);
                let scaleHeight = img.height * (1-spiHeight);
                console.log(img.width)
                ctx.drawImage( img, startWidth-650, starTeachersHeight-50,scaleWidth,scaleHeight);
              };
              img.src = imgData;
            })
          })
        ctx.font = `${this.fontSize}px Arial bolder`;
        n = 26;

      }
      if(data.five && data.five?.length){
        await this.drawRadar(ctx,data.five,height)
      }
    },

    // 将文本按N个字符分割成段落
    splitText(text, n) {
      var paragraphs = [];
      // var punctuation = /[.,\/#!$%\^&\*;:{}=\-_`~()]/;
      for (var i = 0; i < text.length;) {
        if (this.isChinesePunctuation(text.charAt(i+n))){
          paragraphs.push(text.substring(i, i + n));
          i = i+n;
        }else {
          paragraphs.push(text.substring(i, i + n));
          i += n;
        }
      }
      return paragraphs;
    },

    isChinesePunctuation(char) {
      var c = char.charCodeAt(0);
      return (c >= 0x3000 && c <= 0x303F) || (c >= 0xFF00 && c <= 0xFFEF);
    },

    onDrawReport() {
      this.loading = true
      this.$cloud.get('student/export/print/report',{
        classId:this.formSearch['classId'],
        semesterId:this.formSearch['semesterId'],
      }).then(res=>{
        console.log('内容：：',res);
        if(res && res.length){
          // console.log('res'+JSON.stringify(res))
          this.list = res
          let grade = res[0]?.class?.grade;
          let type = res[0]?.semester?.type
          let image = this.getBackgroundImage(grade, type)
          const {front,back} = image
          this.imgs = [];
          this.onDraw( front , back, grade, type).then( () => {
            this.message.close()   // 关闭最后一次提示
            printJS( {
              // 要打印的内容 放在了data里
              printable: this.imgs ,
              // 打印类型 目前为图片样式 可以根据上面的网址进行修改
              type: "image" ,
            } );
            this.imgs = [];
            this.loading = false
          } ).catch( () => {
          } )
        }else{
          this.loading = false
        }
      }).catch(()=>{
        this.loading = false
      })
    } ,

    onDraw( front , back , grade , type ) {
      let that = this;
      return new Promise( async ( resolve ) => {
        let canvas = document.getElementById( "mycanvas" );
        let context = canvas.getContext( "2d" );
        for ( let i = 0 ; i < that.list.length ; i++ ) {
          // 生成报告提示
          if(that.message){
            that.message.close();
          }
          that.messageFun(i + 1)

          let printData = this.handlePrintData( that.list[i] , grade , type );
          canvas.setAttribute( "width" , that.images.width );
          canvas.setAttribute( "height" , String(that.images.height * 2 + 30));
          canvas.setAttribute('backgroundColor','red')
          context.font = `${that.fontSize}px Arial bolder`;
          context.fillStyle = "block";

          // 正面图片
          let frontImg = await that.onDragImage(front)
          // 背面图片
          let backImg = await that.onDragImage(back)

          // 绘制正面图片
          context.drawImage(
            frontImg,
            0 ,
            0 ,
            that.images.width,
            that.images.height + 20,
          );
          this.drawFront( printData , context , grade ,type)   // 绘制正面

          // 绘制背面图片
          context.drawImage(
            backImg,
            0,
            that.images.height + 20,
            that.images.width,
            that.images.height + 20,
          )

          await this.drawBack( printData , context , grade ,type , canvas.height / 2  - 20)   // 绘制背面

          // console.log('cavas::',canvas.toDataURL("image/jpg"))
          // 保存图片

          let image = canvas.toDataURL( "image/jpg" )
          image = changedpi.changeDpiDataUrl(image,300)
          that.imgs.push( image );
          if ( that.imgs.length === that.list.length) {
            resolve();
          }
        }
      } )
    },

    // 绘制图片
    async onDragImage(url,width,height) {
      return new Promise( ( resolve , reject ) => {
        let Img = new Image();
        Img.src = url;
        if(width) Img.width = width
        if ( height ) Img.height = height
        Img.onload = () => {
          resolve(Img);
        };
        Img.onerror = (error) => {
          resolve('');
          // reject(error)
        };
      })
    },

    messageFun(index){
      this.message = this.$message({
        type:'success',
        message:`正在生成第${index}张报告，请耐心等待...`,
        duration: 0,
      })
    }
  }
}
